import {intervalToDuration} from 'date-fns'
import {useEffect, useState} from 'react'

export interface TimeAgoProps
  extends Omit<React.DetailedHTMLProps<React.TimeHTMLAttributes<HTMLTimeElement>, HTMLTimeElement>, 'dateTime'> {
  dateTime: Date
}

export const TimeAgo: React.FC<TimeAgoProps> = ({dateTime, ...rest}) => {
  const [timeAgo, setTimeAgo] = useState(() => formatTimeAgo(dateTime))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(formatTimeAgo(dateTime))
    }, 1000 * 60)
    return () => clearInterval(interval)
  }, [dateTime])

  return (
    <time dateTime={dateTime.toISOString()} {...rest}>
      {timeAgo}
    </time>
  )
}

function formatTimeAgo(date: Date) {
  const duration = intervalToDuration({start: date, end: new Date()})
  if (duration.years) return `${duration.years} ${duration.years === 1 ? 'year' : 'years'} ago`
  if (duration.months) return `${duration.months} ${duration.months === 1 ? 'month' : 'months'} ago`
  if (duration.weeks) return `${duration.weeks} ${duration.weeks === 1 ? 'week' : 'weeks'} ago`
  if (duration.days) return duration.days === 1 ? 'yesterday' : `${duration.days} days ago`
  if (duration.hours) return `${duration.hours} ${duration.hours === 1 ? 'hour' : 'hours'} ago`
  if (duration.minutes) return `${duration.minutes} ${duration.minutes === 1 ? 'minute' : 'minutes'} ago`
  // if (duration.seconds) return `${duration.seconds} ${duration.seconds === 1 ? 'second' : 'seconds'} ago`
  return 'just now'
}
